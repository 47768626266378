export class SondermindReferralTracking {
	private getDomainName(hostName: string): string {
	  return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
	}
  
	private setCookie(name: string, value: string) {
	  const options: Record<string, string | boolean | number> = {
		path: "/",
		secure: true,
		domain: process.env.NODE_ENV === 'production' ? 'sondermind.com' : 'localhost',
		"max-age": 60 * 60 * 24 * 200, // 200 days
	  };
  
	  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
  
	  for (const optionKey in options) {
		updatedCookie += "; " + optionKey;
		const optionValue = options[optionKey];
		if (optionValue !== true) {
		  updatedCookie += "=" + optionValue;
		}
	  }
  
	  document.cookie = updatedCookie;
	}
  
	public update() {
	  if (typeof window === "undefined") return;

	  const searchParams = new URLSearchParams(window.location.search);
	  const referral = searchParams.get("referral");
  
	  if (referral) {
		this.setCookie("referral_source", referral);
	  }
  
	  if (document.referrer) {
		const ref = new URL(document.referrer);
		if (!ref.origin.includes("sondermind.com")) {
		  this.setCookie("EXTERNAL_REFERRAL", this.getDomainName(ref.origin));
		  this.setCookie("EXTERNAL_REFERRAL_DATE", new Date().toISOString());
		  this.setCookie("EXTERNAL_REFERRAL_LP", window.location.href);
		  this.setCookie("EXTERNAL_REFERRAL_SEARCH", window.location.search);
		}
	  }
	}
  }
  