const React = require( "react" );

const { ApplicationProvider } = require( "./src/providers/ApplicationProvider.tsx" );
const { SondermindReferralTracking } = require( "./src/utils/referralTracking.ts" );

export const onClientEntry = () => {
    const referralTracking = new SondermindReferralTracking();
    referralTracking.update();
}

// Wraps every page in a component
export const wrapPageElement = ( { element, props } ) => {

    let _wq = window._wq || [];

    return <ApplicationProvider { ...props } _wq={ _wq }>{ element }</ApplicationProvider>
}